type CallbackOrOptions = (e: MouseEvent) => void | { filter?: string; callback: () => void };

/***
 *
 * A helper for click outside
 *
 * Usage:
 *
 * <div use:clickOutside={onHandler} />
 *
 */

export function clickOutside(node: HTMLElement, optionsOrCallback: CallbackOrOptions) {
  let options = typeof optionsOrCallback === "function" ? { callback: optionsOrCallback } : optionsOrCallback;

  function onClickOutside(event: MouseEvent) {
    if (event.target && !node.contains(event.target as Node) && node !== event.target) {
      options.callback && options.callback(event);
    }
  }

  // Register
  // Why do we use capture?
  // Learn about capture here (https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Building_blocks/Events#Event_bubbling_and_capture)
  // Capture means that our handler would receive the event *before* the regular event bubbling phase.
  // This is extremely useful when you want the "clickOutside" handle to fire before an external button "onClick"
  document.addEventListener("mousedown", onClickOutside, { capture: true });

  return {
    destroy: () =>
      document.removeEventListener("mousedown", onClickOutside, {
        capture: true
      })
  };
}
